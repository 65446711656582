import { Heading, Pane } from "evergreen-ui";
import React from "react";
import SearchBox from "@components/Searchbox";
import Error404 from "@assets/svgs/Error404";
import { useTranslation } from "next-i18next";
import { Meta } from "@components/Meta";

export default function Error() {
  const { t } = useTranslation("common");

  return (
    <>
      <Meta
        title={t("error.404.title")}
        url={`https://tool.douni.one/404`}
        description={t("error.404.desc")}
        i18N={false}
      />
      <Pane display="flex" alignItems="center" className="tool-pane">
        <Pane
          display="flex"
          flexDirection="column"
          alignItems="center"
          flex={1}
        >
          <>
            <Error404 className="w-full h-auto" />
            <Heading marginTop={50} size={600} className="text-center">
              {t("error.404.desc")}
            </Heading>
            <Pane marginTop={30} width="70%">
              <SearchBox />
            </Pane>
          </>
        </Pane>
      </Pane>
    </>
  );
}

export { getStaticProps } from "@components/layouts/withI18n";
